/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A Merry Christmas with the family - in this social media commercial for the music app Audanika by Grace Cloud GmbH, the images are meant to awake feelings of warmth, childlikeness and Christmas anticipation. We wanted to fuel the desire to spend time together with one's family during the holidays."), "\n", React.createElement(_components.p, null, "This film only works during Christmas time, as a certain priming is necessary, which automatically arises in December due to external influences. These feelings build up the video, let it swell like waves to touch the viewer emotionally."), "\n", React.createElement(_components.p, null, "To be particularly authentic, the app developer himself is the family father and narrator for the video. We shot with his own children, who turned out to be excellent actors. They quickly forgot about the camera and just had a lot of fun shooting."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
