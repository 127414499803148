import GATSBY_COMPILED_MDX from "/Users/denny/Developer/Foxel Website/foxel_website/projects/audanika_xmas.en.mdx";
import React from "react";
import {graphql} from "gatsby";
import Layout from "../src/components/layout";
import styled from "styled-components";
import {useI18next} from 'gatsby-plugin-react-i18next';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {SeoComponent} from "../src/components/seo";
import breakpoints from "../src/components/breakpoints";
import VideoJS from "../src/components/videojs";
import "videojs-sprite-thumbnails";
const Project = (styled.article)`
background: linear-gradient(rgba(20, 88, 179,1.0), rgba(7, 60, 120,1.0)) ;
position: relative;
padding: 120px 120px 60px 120px;
z-index:4;
width: 100vw;
min-height: 100vh;
box-sizing: border-box;
overflow: hidden;
@media (max-width: ${breakpoints.tablet}) {
  padding: 120px 80px 40px 80px;
}
@media (max-width: ${breakpoints.mobileL}) {
  padding: 90px 40px 40px 40px;
}
@media (max-width: ${breakpoints.mobileM}) {
  padding: 60px 40px 40px 40px;
}
`;
const Headline = (styled.h1)`
font-size: 3.5rem;
@media (max-width: ${breakpoints.desktopHD}) {
  font-size: 2.5rem;
}
@media (max-width: ${breakpoints.tablet}) {
  font-size: 2rem;
}
@media (max-width: ${breakpoints.mobileL}) {
  font-size: 1.5rem;
}
`;
const ProjectContent = (styled.div)`
display: flex;
max-width: 1024px;
margin: 0 auto;
padding: 0;
flex-direction: column;
align-items: center;
@media (max-width: ${breakpoints.desktopHD}) {
  max-width: 800px;
}
`;
const Paragraph = (styled.div)`
line-height: 2;
font-size: 13pt;
position: relative;
z-index:4;
color: #ffffff;
padding: 40px,0,40px,0;
`;
function ProjectPage({data, children}) {
  const {language} = useI18next();
  const {t} = useTranslation();
  const playerRef = React.useRef(null);
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: `/thumbs/${data.mdx.frontmatter.featuredImage.relativePath}`,
    html5: {
      vhs: {
        overrideNative: true
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false
    },
    sources: [{
      src: `/videos/${data.mdx.frontmatter.video_path}/master.m3u8`
    }]
  };
  const handlePlayerReady = player => {
    playerRef.current = player;
    player.on('waiting', () => {
      VideoJS.log('player is waiting');
    });
    player.on('dispose', () => {
      VideoJS.log('player will dispose');
    });
  };
  return React.createElement(Layout, {
    manualTrans: true,
    pageLang: language,
    title: `${data.site.siteMetadata.title} | ${t('Index-Page.Title')}`,
    description: t(data.mdx.frontmatter.description)
  }, React.createElement(Project, null, React.createElement(ProjectContent, null, React.createElement(Headline, null, data.mdx.frontmatter.title), React.createElement(VideoJS, {
    options: videoJsOptions,
    onReady: handlePlayerReady
  }), React.createElement(Paragraph, null, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ProjectPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query($slug: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        video_path
        slug
        locale
        date
        description
        featuredImage {
          relativePath
        }
      }
    }
    site {
        siteMetadata {
        title
        author
        defaultCharset: charset
        description
        siteUrl
        }
    }
  }
`;
export const Head = ({data}) => {
  return React.createElement(React.Fragment, null, React.createElement(SeoComponent, {
    title: `${data.site.siteMetadata.title} | ${data.mdx.frontmatter.title}`,
    description: data.mdx.frontmatter.description
  }), React.createElement("script", {
    type: "application/ld+json"
  }, `
        {
        "@context": "https://schema.org/",
        "@type": "VideoObject",
        "contentUrl": "${data.site.siteMetadata.siteUrl}/videos/${data.mdx.frontmatter.video_path}/master.m3u8",
        "name": "${data.site.siteMetadata.title} | ${data.mdx.frontmatter.title}",
        "description": "${data.mdx.frontmatter.description}",
        "thumbnailUrl": "${data.site.siteMetadata.siteUrl}/thumbs/${data.mdx.frontmatter.featuredImage.relativePath}",
        "uploadDate": "${data.mdx.frontmatter.date}",
        "author": {
          "@type": "Organization",
          "name": "Foxel Media GmbH"
        }
      }
    `));
};
